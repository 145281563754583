@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 1200px) {
  .page1 {
    box-shadow: 0px;
    display: flex;
    align-items: center;
    padding: 10px;
    box-shadow: none;
    border: 2px solid #3f51b5;
    flex-direction: column;
  }

  .newAccountButton {
    font-size: 16px !important;
    width: auto !important;
    height: auto !important;
    padding: 8px 16px !important;
  }

  .homeImg {
    width: 60%;
  }

  .overlay h5 {
    font-size: 1.5rem;
    text-align: end;
    margin-bottom: 1.5rem;
  }

  .overlay {
    padding: 0 10px;
  }
}

@media (max-width: 480px) {
  .newAccountButton {
    font-size: 14px !important;
    width: auto !important;
    height: auto !important;
    padding: 6px 12px !important;
  }

  .overlay h5 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  .overlay img {
    width: 100%;
    height: auto;
  }

  .homeImg {
    display: none;
  }
  .page2 {
    flex-direction: column;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    scale: 0.7;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}
@keyframes translateL {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes translateR {
  0% {
    opacity: 0;
    transform: translateX(300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.sidenav_sideNav__3Z4J2 {
    height: 100%;
    width: 85px;
    background-color: white;
    border-right: 2px solid #3f51b5;
    position: fixed;
    text-align: center;
    transition: all 0.5s ease 0s;
    overflow-y: auto;
}

.sidenav_open__3GuWa {
    right: inherit;
    z-index: 1100;
    width: 300px;
    text-align: left;
}


.sidenavitem_active__3vq46 {
  background-color: #3f51b5;
  color: white;
}

.sidenavitem_icon__24DyD {
  width: 30px !important;
  height: 30px !important;
  text-align: center;
  color: white !important;
}

.sidenavitem_active__3vq46 .sidenavitem_icon__24DyD {
  color: white;
}
.sidenavitem_label__1cHpc {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  padding-bottom: 5px;
  color: white;
}

.homeintro_box__194Ca {
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.5), 0 2px 4px 0 rgba(0,0,0,.5);
    -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,.5), 0 2px 4px 0 rgba(0,0,0,.5);
    -moz-box-shadow: 0 2px 4px 0 rgba(0,0,0,.5),0 2px 4px 0 rgba(0,0,0,.5);
    background-color: #fff;
    padding: 38px 47px 28px 53px;
    margin-bottom: 14px;
    border-radius: 10px;
}

ul {
    padding: 0;
}



li {
    position: relative;
    font-size: 1.125em;
    margin: 5px;
}

span {
    font-weight: bold;
    margin-left: 7.5px;
}
.feedbackCard_cardContainer__pl7Mt {
  display: flex;
  justify-content: center;
}
.feedbackCard_card__1ze8s {
  width: 350px;
  height: 300px;
  margin: 18px;
  padding: 10px;
  border-radius: 36px;
  border: 3px solid #3f51b5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.feedbackCard_feedback__2oB9k {
  font-size: 1.1rem;
  font-family: "Roberts", sans-serif;
}
@media screen and (max-width: 480px) {
  .feedbackCard_card__1ze8s {
    width: 280px;
    margin-right: 30px;
  }
  .feedbackCard_feedback__2oB9k {
    font-size: 1rem;
  }
}

.home_topContent__3ip1a {
  max-width: 500px;
}

.home_featureImage__35XV3 {
  max-width: 125px;
}

.home_body__2ERow {
  background-color: #f3f5ff;
  background-image: radial-gradient(
      #3f51b5 0.9500000000000001px,
      transparent 0.9500000000000001px
    ),
    radial-gradient(#3f51b5 0.9500000000000001px, #ffffff 0.9500000000000001px);
  background-size: 38px 38px;
  background-position: 0 0, 19px 19px;
}
.home_videoContainer__2FCKb iframe,
.home_videoContainer__2FCKb object,
.home_videoContainer__2FCKb embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.home_newAccountButton__3TkVu {
  font-size: 20px !important;
  background-color: #3f51b5 !important;
  padding: 10px 20px !important;
  color: white !important;
  width: 237px !important;
  height: 66px !important;
  border-radius: 60px !important;
  border: 1px ridge #3f51b5 !important;
}

.home_newAccountButton__3TkVu:hover {
  background-color: white !important;
  color: #3f51b5 !important;
  border: 1px solid #3f51b5 !important;
  transition: all 0.3s ease-in-out !important;
}

.home_page2__1SJ5M {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem 0;
}
.home_page2__1SJ5M video {
  border: 5px solid #3f51b5 !important;
  border-radius: 20px;
  width: 80%;
  height: 80%;
  object-fit: cover;
  margin: 6rem 0;
  animation: home_translateR__3UJRo linear;
  animation-timeline: view();
  animation-range: entry 0 cover 30%;
}
.home_page2__1SJ5M h3 {
  font-size: 2.3rem;
  color: #000000;
  font-weight: bold;
  font-family: "poppins", sans-serif;
  text-align: start;
  animation: home_translateR__3UJRo linear;
  animation-timeline: view();
  animation-range: entry 0 cover 30%;
}
.home_title__2Ftu- {
  font-size: 2.3rem;
  color: #000000;
  font-weight: bold;
  font-family: "poppins", sans-serif;
  text-align: start;
  margin: 2rem 0;
  background-color: white;
}
.home_title__2Ftu- span {
  color: #3f51b5;
  font-weight: bold;
}
.home_page2__1SJ5M h3 span {
  color: #3f51b5;
  font-weight: bold;
}
.home_page2__1SJ5M p {
  text-transform: uppercase;
  font-weight: bold;
  line-height: 45px;
  font-size: 1.4rem;
  color: #000000;
  margin-bottom: 3rem;
  font-family: "Roboto", sans-serif;
  text-align: center;
  background-color: white;
  animation: home_translateR__3UJRo linear;
  animation-timeline: view();
  animation-range: entry 0 cover 30%;
}
.home_page2__1SJ5M p span {
  color: #3f51b5;
  font-weight: bold;
}
.home_page2Img__1Yvrw {
  width: 100%;
  max-width: 900px;
  height: auto;
  margin-bottom: 4rem;
  object-fit: cover;
}
.home_tiersContainer__2BiZQ {
  margin: 3rem auto;
}

.home_numbersContainer__3pk4t {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 160px;
  max-width: 900px;
  margin: 1rem auto;
  background-color: aliceblue;
  padding: 1rem;
  border: 2px dotted #3f51b5;
  border-radius: 16px;
  /* box-shadow: inset 0 -1em 1em #3f51b542, 0.3em 0.3em 1em rgba(31, 31, 31, 0.6); */
  animation: home_translateL__wjFzM linear;
  animation-timeline: view();
  animation-range: entry 0 cover 30%;
}
.home_numbers__1AEaY {
  padding-left: 2rem;
  flex: 2 1;
  text-align: start;
  margin: 1rem 0;
}
.home_numbers__1AEaY h3 {
  font-size: 1.5rem;
  letter-spacing: 3px;
  color: #ee6c4d;
  font-weight: bold;
  margin-bottom: 1rem;
}
.home_numbers__1AEaY h4 {
  font-size: 1rem;
  letter-spacing: 3px;
  color: #3f51b5;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-family: "Roboto", sans-serif;
}
.home_numbersImg__1ita4 {
  flex: 2 1;
  display: flex;
  justify-content: center;
}
.home_numbersImg__1ita4 img {
  width: auto;
  height: 100%;
}

.home_page4__3iWcs {
  margin: 8rem 0;
  animation: home_translateR__3UJRo linear;
  animation-timeline: view();
  animation-range: entry 0 cover 30%;
}
.home_feature__2s6J6 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  height: 90%;
  text-align: center;
  margin: 1rem 0;
  border: 3px solid #3f51b5;
  box-shadow: #3f51b5 30px -16px;
  border-radius: 16px;
  padding: 1.5rem;
  background-color: rgb(255, 255, 255);
  animation: home_translateR__3UJRo linear;
  animation-timeline: view();
  animation-range: entry 0 cover 25%;
}
.home_feature__2s6J6 div {
  display: flex;
  justify-content: center;
}

.home_feature__2s6J6 h4 {
  font-size: 1.3rem;
  color: #3f51b5;
  font-weight: bold;
  margin: 0 auto;
  text-align: center;
  font-family: "poppins", sans-serif;
}

.home_feature__2s6J6 P {
  font-size: 1.1rem;
  color: #ee6c4d;
  font-weight: bold;
  margin: 0 auto;
  text-align: center;
  font-family: "poppins", sans-serif;
}
.home_featureImg__1rQzD {
  width: 160px;
  height: 160px;
  margin: 0 auto;
}
.home_featureImg__1rQzD img {
  width: auto;
  height: 100%;
}
.home_descriptionIcon__PCxcA {
  font-size: 48px;
}

@media (max-width: 1200px) {
  .home_page2__1SJ5M {
    margin: 2rem 0;
  }
  .home_page2__1SJ5M video {
    border: 5px solid #3f51b5 !important;
    border-radius: 20px;
    width: 80%;
    height: 80%;
    object-fit: cover;
    margin: 3rem 0;
  }

  .home_newAccountButton__3TkVu {
    font-size: 16px !important;
    width: 237px !important;
    height: 66px !important;
    padding: 8px 16px !important;
    margin: 1rem 0 !important;
  }

  .home_title__2Ftu- {
    font-size: 26px;
    color: #000000;
    font-weight: bold;
    font-family: "poppins", sans-serif;
    text-align: start;
    margin: 2rem 0;
    background-color: white;
  }
}

@media (max-width: 480px) {
  .home_newAccountButton__3TkVu {
    font-size: 14px !important;
    width: 200px !important;
    height: 55px !important;
    padding: 6px 12px !important;
    margin: 1rem 0 !important;
  }

  .home_page2__1SJ5M {
    flex-direction: column;
  }
  .home_page2__1SJ5M h3 {
    font-size: 26px;
  }
  .home_page2__1SJ5M p {
    text-transform: uppercase;
    font-weight: bold;
    line-height: 30px;
    font-size: 1.1rem;
    color: #000000;

    text-align: center;
  }
  .home_feature__2s6J6 {
    width: 90%;
    height: 90%;
  }
  .home_numbersContainer__3pk4t {
    height: 180px;
  }
  .home_numbers__1AEaY h3 {
    font-size: 1.5rem;
    letter-spacing: 3px;
    color: #ee6c4d;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .home_numbers__1AEaY h4 {
    font-size: 0.9rem;
    letter-spacing: 3px;
    color: #3f51b5;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  .home_title__2Ftu- {
    font-size: 26px;
    color: #000000;
    font-weight: bold;
    font-family: "poppins", sans-serif;
    text-align: start;
    margin: 2rem 0;
    background-color: white;
  }
  .home_page2__1SJ5M video {
    width: 100%;
    height: 100%;
  }
}

@keyframes home_appear__1pi_y {
  0% {
    opacity: 0;
    scale: 0.7;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}
@keyframes home_translateL__wjFzM {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes home_translateR__3UJRo {
  0% {
    opacity: 0;
    transform: translateX(300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.page1_page1__318tK {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  border-radius: 16px;
  border: 3px solid #3f51b5;
  box-shadow: #3f51b5 60px -16px;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 8rem auto;
  animation: page1_translateL__30ycR 0.5s linear;
  animation-range: entry 0 cover 50%;
}

.page1_newAccountButton__15oEH {
  font-size: 20px !important;
  background-color: #3f51b5 !important;
  padding: 10px 20px !important;
  color: white !important;
  width: 237px !important;
  height: 66px !important;
  border-radius: 60px !important;
  border: 1px ridge #3f51b5 !important;
}

.page1_newAccountButton__15oEH:hover {
  background-color: white !important;
  color: #3f51b5 !important;
  border: 1px solid #3f51b5 !important;
  transition: all 0.3s ease-in-out !important;
}

.page1_homeImg__7aATN {
  width: 70%;
  max-width: 100%;
  height: auto;
  margin-bottom: 2rem;
}

.page1_logo__Z4kzK {
  max-width: 100%;
  height: auto;
}

.page1_overlay__3DSz1 h5 {
  font-size: 2rem;
  width: 100%;
  color: #3f51b5; /* Adjust text color as needed */
  margin-right: 1rem;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  border-radius: 16px;
  margin-bottom: 3rem;
  text-align: end;
  font-weight: bold;
}
.page1_overlay__3DSz1 h5 span {
  color: #ee6c4d;
  font-weight: bold;
}
@media (max-width: 1200px) {
  .page1_page1__318tK {
    box-shadow: 0px;
    display: flex;
    align-items: center;
    padding: 10px;
    box-shadow: none;
    border: 2px solid #3f51b5;
    flex-direction: column;
    margin: 10rem auto;
  }

  .page1_newAccountButton__15oEH {
    font-size: 16px !important;
    width: 237px !important;
    height: 66px !important;
    padding: 8px 16px !important;
    margin: 1rem 0 !important;
  }

  .page1_homeImg__7aATN {
    width: 60%;
  }

  .page1_overlay__3DSz1 h5 {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .page1_overlay__3DSz1 {
    padding: 0 10px;
  }
}

@media (max-width: 480px) {
  .page1_newAccountButton__15oEH {
    font-size: 14px !important;
    width: 200px !important;
    height: 55px !important;
    padding: 6px 12px !important;
    margin: 1rem 0 !important;
  }

  .page1_overlay__3DSz1 h5 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  .page1_overlay__3DSz1 img {
    width: 100%;
    height: auto;
  }

  .page1_homeImg__7aATN {
    display: none;
  }
  .page1_page2__2oFmp {
    flex-direction: column;
  }
}

@keyframes page1_appear__lszWb {
  0% {
    opacity: 0;
    scale: 0.7;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}
@keyframes page1_translateL__30ycR {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes page1_translateR__2Mhts {
  0% {
    opacity: 0;
    transform: translateX(300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.resourcelist_chapter__3NYIv {
  cursor: pointer;
  background-color: white !important;
}

.course_container__2wVrZ {
  margin-top: 65px;
  padding-right: 20px;
  padding-left: 20px;
}
@media screen and (max-width: 600px) {
  .course_container__2wVrZ {
    padding-right: 0px;
    padding-left: 0px;
  }
}

/* Ensure the card container has perspective for the 3D effect */
.studentCourseItem_cardContainer__1XWOk {
  perspective: 1000px;
  width: 100%; /* Ensure full width of the card */
  height: 300px; /* Adjust height as per your needs */
}

/* The card itself */
.studentCourseItem_card__2jwXv {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s ease-in-out;
}
.studentCourseItem_cardMobile__2Rrqz {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;

  align-items: center;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #5c6bc0;
  background-color: white;
  border: 2px solid #5c6bc0;
}
.studentCourseItem_mobileButton__2jI48 {
  background-color: white !important;
  color: #5c6bc0 !important;
  border: 2px solid #5c6bc0 !important;
  border-radius: 5px !important;
  padding: 10px 15px !important;
  cursor: pointer !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 18px;
}
.studentCourseItem_mobileimg__wKWxA {
  width: 85px;
  height: 85px;
  border-radius: 8px;
  margin-bottom: 4px;
}
.studentCourseItem_button__3XRYj {
  background-color: #5c6bc0 !important;
  color: white !important;
  border: 2px solid white !important;
  border-radius: 5px !important;
  padding: 10px 15px !important;
  cursor: pointer !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 18px;
}

/* Trigger the flip on hover */
.studentCourseItem_cardContainer__1XWOk:hover .studentCourseItem_card__2jwXv {
  transform: rotateY(180deg);
}

/* Front and back side of the card */
.studentCourseItem_cardFront__3JieU,
.studentCourseItem_cardBack__2I6J4 {
  width: 100%;
  height: 80%;
  position: absolute;
  backface-visibility: hidden; /* Hide the backside when flipped */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: white;
}
.studentCourseItem_smallIcon__3ir7A {
  font-size: 16px;
  position: absolute;
  top: -20px;
  right: -20px;
}

/* Front side styling */
.studentCourseItem_cardFront__3JieU {
  background-color: #5c6bc0; /* Indigo color */
}

/* Back side starts rotated */
.studentCourseItem_cardBack__2I6J4 {
  background-color: #ffffff;
  color: #5c6bc0;
  justify-content: space-evenly;
  flex-direction: column;
  border: 2px solid #5c6bc0; /* Reddish color */
  transform: rotateY(180deg);
}
.studentCourseItem_buttonBack__2Q7X2 {
  background-color: white !important;
  color: #5c6bc0 !important;
  border: 2px solid #5c6bc0 !important;
  border-radius: 5px !important;
  padding: 10px 15px !important;
  cursor: pointer !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
}
.studentCourseItem_buttonBack__2Q7X2:hover {
  background-color: #5c6bc0 !important;
  color: white !important;
}
.studentCourseItem_img__37n6U {
  width: 100px;
  height: 100%;
  border-radius: 8px;
}
@media screen and (max-width: 600px) {
  .studentCourseItem_smallIcon__3ir7A {
    font-size: 12px !important;
    height: 42px !important;
    top: -20px;
    right: -25px;
  }
}

.offer_card__3xTNu {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 1rem 0;
  border: 3px solid #3f51b5;
  background-color: #ffffff;
  border-radius: 16px;
}
.offer_title__1ZwIj {
  width: 100% !important;
  font-size: 1.3rem !important;
  font-weight: bold;
  color: white;
  border: 3px solid #3f51b5 !important;
  background-color: #3f51b5 !important;
  border-radius: 12px 12px 0 0;
}
.offer_smallDescription__1iOTr {
  font-family: "roboto", sans-serif;
  font-size: 1.3rem !important;
  margin-top: 2rem;
  color: #3f51b5;
}
.offer_content__2iN9R img {
  width: 180px;
  height: 180px;
  margin-bottom: 1rem;
  /* border-radius: 50%; */
}
.offer_content__2iN9R {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem 1rem 2.5rem 1rem;
}

.offer_description__2Pdh7 li {
  text-align: start;
  font-size: 1rem !important;
  margin: 1rem 0;
  font-family: "Roboto", sans-serif;
}
.offer_description__2Pdh7 {
  padding: 1rem;
}

.offer_doneIcon__2Nwg4 {
  margin-right: 3px;
  color: #00b894;
}
.offer_price___swW8 {
  font-size: 1.5rem !important;
  font-weight: bold;
  margin: 2rem auto;
  width: 100%;
}
.offer_buyButton__1QE69 {
  font-size: 16px !important;
  background-color: #3f51b5 !important;
  padding: 10px 20px !important;
  color: white !important;
  width: 276px !important;
  height: 66px !important;
  border-radius: 60px !important;
  border: 1px ridge #3f51b5 !important;
}

.offer_buyButton__1QE69:hover {
  background-color: white !important;
  color: #3f51b5 !important;
  border: 1px solid #3f51b5 !important;
  transition: all 0.3s ease-in-out !important;
}

.offer_disabledButton__pG5az {
  font-size: 16px !important;
  background-color: white !important;
  color: #3f51b5 !important;
  border: 1px solid #3f51b5 !important;
  width: 80% !important;
  height: 66px !important;
  border-radius: 60px !important;
}
.offer_priceButton__1vqdO {
  display: flex;
  margin: 0 1rem;

  flex-direction: row;
  justify-self: space-between;
  grid-gap: 4rem;
  gap: 4rem;
}
.offer_priceButtonWrapper__OIaH7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
}
/* Container for the number buttons */
.offer_numberButtons__1SwYg {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px; /* Space between buttons */
}

/* Individual number button styling */
.offer_numberButton__NQeXq {
  width: 30px; /* Width of each button */
  height: 30px; /* Height of each button */
  border-radius: 50%; /* Rounded corners for circular shape */
  border: 1px solid #3f51b5; /* Optional border */
  background-color: #ffffff; /* Background color */
  color: #3f51b5; /* Text color */
  font-size: 14px; /* Font size */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Cursor changes to pointer on hover */
}
.offer_selected__2w_MD {
  background-color: #3f51b5 !important; /* Optional hover effect */
  color: #ffffff !important;
}

.offer_numberButton__NQeXq:hover {
  background-color: #3f51b5; /* Optional hover effect */
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}
.offer_months__2PpuN {
  font-size: 14px;
  font-family: "roboto", sans-serif;
  font-weight: bold;
}

@media (max-width: 480px) {
  .offer_card__3xTNu {
    max-height: 700px;
  }
  .offer_content__2iN9R img {
    width: 140px;
    height: 140px;
    margin: 0;
  }
  .offer_priceButton__1vqdO {
    margin-bottom: 5px;
  }
  .offer_description__2Pdh7 {
    margin-bottom: 0;
  }
  .offer_numberButtons__1SwYg {
    grid-gap: 6px;
    gap: 6px;
  }
  .offer_numberButton__NQeXq {
    width: 28px;
    height: 28px;
    font-size: 12px;
  }
  .offer_months__2PpuN {
    font-size: 14px;
  }
  .offer_price___swW8 {
    font-size: 1.4rem !important;
    margin: auto;
  }
  .offer_buyButton__1QE69 {
    width: 90% !important;
    height: 50px !important;
    font-size: 14px !important;
    margin-top: 12px !important;
  }
  .offer_priceButton__1vqdO {
    grid-gap: 2rem;
    gap: 2rem;
  }
  .offer_disabledButton__pG5az {
    width: 70% !important;
    height: 50px !important;
  }
  .offer_doneIcon__2Nwg4 {
    margin-right: 2px;
  }
  .offer_description__2Pdh7 li {
    font-size: 1rem !important;
    margin: 0.7rem auto;
  }
  .offer_smallDescription__1iOTr {
    font-size: 1rem !important;
    margin: 0.5rem;
    display: none;
  }
  .offer_title__1ZwIj {
    font-size: 1.1rem !important;
  }
}

.offerList_title__1RWEG {
  color: #3f51b5;
  font-family: "poppins", sans-serif;
}
.offerList_container__29kXP {
  padding: 5rem;
}
@media (max-width: 480px) {
  .offerList_container__29kXP {
    padding: 3rem 0rem;
  }
}

.home_topContent__3F-Ua {
    max-width: 500px;
}

.home_featureImage__oVsFs {
    max-width: 125px;
}
.exploreOffers_container__hIIJJ {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  padding: 3rem;
  background-color: #f3f5ff;
  background-image: radial-gradient(
      #3f51b5 0.9500000000000001px,
      transparent 0.9500000000000001px
    ),
    radial-gradient(#3f51b5 0.9500000000000001px, #ffffff 0.9500000000000001px);
  background-size: 38px 38px;
  background-position: 0 0, 19px 19px;
}
.exploreOffers_container__hIIJJ h1 {
  font-family: "poppins", sans-serif;
  font-size: 3rem;
  font-weight: bold;
  color: #3f51b5;
  margin-bottom: 2.5rem;
  background-color: white;
}
.exploreOffers_container__hIIJJ p {
  font-family: "poppins", sans-serif;
  font-size: 1.15rem;
  font-weight: 600;
  background-color: white;
}

.exploreOffers_menu__3kSvy {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  grid-gap: 64px;
  gap: 64px;
  flex-wrap: wrap;
  justify-content: center;
}
.exploreOffers_menuItem__-kN-h {
  padding: 0.4rem;
  font-family: "poppins", sans-serif;
  font-size: 1.15rem;
  font-weight: 600;
  background-color: white;
  cursor: pointer;
}
.exploreOffers_listItem__2PgZN {
  padding: 0.4rem;
  font-family: "poppins", sans-serif;
  font-size: 1.15rem;
  font-weight: 600;
  cursor: pointer;
}
.exploreOffers_selected__1BRl6 {
  color: #3f51b5 !important;
  border-bottom: 2px solid #3f51b5 !important;
}
.exploreOffers_menuItem__-kN-h:hover {
  color: #3f51b5 !important;
  transition: all 0.2s ease-in-out !important;
}

html {
  scroll-behavior: smooth;
}
.prepa_container__3E2_I {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  padding: 3rem;
  margin: 0.75rem;
  background-color: #f3f5ff;
  background-image: radial-gradient(
      #3f51b5 0.9500000000000001px,
      transparent 0.9500000000000001px
    ),
    radial-gradient(#3f51b5 0.9500000000000001px, #ffffff 0.9500000000000001px);
  background-size: 38px 38px;
  background-position: 0 0, 19px 19px;
}
.prepa_container__3E2_I h1 {
  font-family: "poppins", sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  color: #3f51b5;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
  background-color: white;
}
.prepa_offersButton__-C21O {
  font-size: 16px !important;
  padding: 10px 20px !important;
  width: 276px !important;
  height: 66px !important;
  border-radius: 60px !important;
  transition: all 0.3s !important;
}
.prepa_buttonContainer__2Uv7g {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.prepa_container__3E2_I h3 {
  font-family: "poppins", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: #616161;
  margin-bottom: 0.5rem;
  background-color: white;
}
.prepa_container__3E2_I p {
  font-family: "poppins", sans-serif;
  font-size: 1.15rem;
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 0;
  background-color: white;
}
.prepa_buttonContainer__2Uv7g {
  margin: 15px 0;
}
.prepa_button__25o-j {
  margin: 3rem !important;
  font-family: "poppins", sans-serif !important;
  font-size: 2.15rem !important;

  cursor: pointer !important;
  border-radius: 50% !important;
  text-align: center !important;
  width: 150px !important;
  height: 150px !important;

  transition: all 0.3s !important;
}
.prepa_button1__m2bjf {
  color: #4caf50 !important;
  background-color: #ffffff !important;
  border: 1px solid #4caf50 !important;
}
.prepa_button2__on5vu {
  color: #3f51b5 !important;
  background-color: #ffffff !important;
  border: 1px solid #3f51b5 !important;
}
.prepa_button3__1fSj3 {
  color: #f44336 !important;
  background-color: #ffffff !important;
  border: 1px solid #f44336 !important;
}
.prepa_button1__m2bjf:hover {
  color: #ffffff !important;
  background-color: #4caf50 !important;
}
.prepa_button2__on5vu:hover {
  color: #ffffff !important;
  background-color: #3f51b5 !important;
}
.prepa_button3__1fSj3:hover {
  color: #ffffff !important;
  background-color: #f44336 !important;
}
.prepa_cardContainer__1zH4P {
  box-shadow: #3f51b5 0px 0px 10px 0px;
  min-height: 400px;
  margin: 0 auto !important;
  width: 480px !important;
}
.prepa_green__3L22x {
  box-shadow: #4caf50 0px 0px 10px 0px;
  border: 1px solid #4caf50;
}
.prepa_blue__2YU7w {
  box-shadow: #3f51b5 0px 0px 10px 0px;
  border: 1px solid #3f51b5;
}
.prepa_red__243fW {
  box-shadow: #f44336 0px 0px 10px 0px;
  border: 1px solid #f44336;
}
.prepa_header__2dRM9 {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  margin: 7px 0;
}
.prepa_header__2dRM9 h4 {
  font-family: "poppins", sans-serif !important;
  font-size: 1.5rem !important;
  font-weight: bold !important;
  color: #727272 !important;
}
.prepa_panel1__R6kSP {
  font-family: "poppins", sans-serif !important;
  font-weight: bold !important;
  font-size: 1.1rem !important;
  margin: 10px 0 !important;
}
@media screen and (max-width: 490px) {
  .prepa_container__3E2_I {
    padding: 1.5rem 0.5rem !important;
  }
  .prepa_container__3E2_I p {
    text-align: center;
  }
  .prepa_button__25o-j {
    margin: 1rem !important;
    width: 100px !important;
    height: 100px !important;
    font-size: 1.5rem !important;
  }
  .prepa_cardContainer__1zH4P {
    min-height: 400px;
    margin: 0 auto !important;
    width: 95% !important;
  }
  .prepa_header__2dRM9 h4 {
    font-size: 1.3rem !important;
  }
}

li {
  font-family: "poppins", sans-serif;
  color: rgb(107, 107, 107);
  font-size: 1.1rem;
}

.signin_signInForm__3Ln2W {
    max-width: 500px;
    width: 100%;
}
.request-new-password_signInForm__1UCFC {
    max-width: 500px;
    width: 100%;
}
.new-password_signInForm__2oIGT {
    max-width: 500px;
    width: 100%;
}
.resourcelist_chapter__1CmM1 {
  cursor: pointer;
}
.maincontent_mainContent__1yZQD {
  height: 100%;
  overflow-y: auto;
  width: calc(100%);
  background-color: white;
  transition: all 0.5s ease 0s;
}

.maincontent_rotated__2z1nc {
  transform: translate3d(100px, 0px, -600px) rotateY(-20deg);
  transform-style: preserve-3d;
  overflow: hidden;
}

.maincontent_page__3_Wcb {
  min-height: calc(100% - 140px);
}

.topheader_topHeader__1En32 {
  display: flex !important;
  align-items: center !important;
}
.topheader_menu__1Gjjg {
  display: none !important;
}
.topheader_authSingleBtn__GwXO2 {
  background-color: #ffffff !important;
  color: #000000 !important;
  width: 200px !important;
  height: 49px !important;
  border-radius: 60px !important;
  border: 1px ridge #3f51b5 !important;
  border: 1px ridge #000000 !important;
}

.topheader_authSingleBtn__GwXO2:hover {
  background-color: white !important;
  color: #3f51b5 !important;
  border: 1px solid #3f51b5 !important;
  transition: all 0.3s ease-in-out !important;
}

.topheader_newAccountButton__2tcCd {
  background-color: #3f51b5 !important;
  color: white !important;
  width: 200px !important;
  height: 49px !important;
  border-radius: 60px !important;
  border: 1px ridge #3f51b5 !important;
  border: 1px ridge #3f51b5 !important;
}

.topheader_newAccountButton__2tcCd:hover {
  background-color: white !important;
  color: #3f51b5 !important;
  border: 1px solid #3f51b5 !important;
  transition: all 0.3s ease-in-out !important;
}
.topheader_appbarItems__2K56n {
  margin-left: 1rem !important; /* Set left margin to 1rem (16px) */
  margin-right: 1rem !important;
  padding: 1rem;
  height: 100%;
  color: #000000 !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 18px !important;
}
.topheader_menuItems__11H-3 {
  margin-left: 1rem !important; /* Set left margin to 1rem (16px) */
  margin-right: 1rem !important;
  padding: 1rem;
  height: 100%;
  color: #000000 !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 20px !important;
}

.topheader_appbarItems__2K56n:hover {
  scale: 1.1;
  border-bottom: 2px solid #3f51b5 !important;
  color: #3f51b5 !important;
  transition: all 0.2s ease-in-out !important;
}
@media screen and (max-width: 1200px) {
  .topheader_appbarItems__2K56n {
    margin-left: 0.8rem !important; /* Set left margin to 0.8rem (0.86px) */
    margin-right: 0.8rem !important;
    padding: 0.8rem;
    height: 100%;
    color: #000000 !important;
    font-family: "Roboto", sans-serif !important;
    font-size: 14px !important;
  }
  .topheader_newAccountButton__2tcCd,
  .topheader_authSingleBtn__GwXO2 {
    width: 150px !important;
    height: 40px !important;
    font-size: 11px !important;
  }
  .topheader_buttonIcon__2m-NL {
    display: none !important;
  }
}
@media screen and (max-width: 868px) {
  .topheader_appbarItems__2K56n {
    display: none !important;
  }
  .topheader_newAccountButton__2tcCd,
  .topheader_authSingleBtn__GwXO2 {
    display: none !important;
  }
  .topheader_menu__1Gjjg {
    display: block !important;
    margin-left: auto !important;
    width: 30px !important;
  }
}

.layout_layout__3fTPj {
  height: 100%;
  perspective: 1500px;
  overflow: hidden;
}

@keyframes layout_loadingAnimation__3lFSz {
  0 % {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0;
  }
}

.layout_loadingDivs__27TTh div {
  position: absolute;
  border-width: 4px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: layout_loadingAnimation__3lFSz 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.layout_loadingDivs__27TTh div:nth-child(1) {
  border-color: #e90c59;
}

.layout_loadingDivs__27TTh div:nth-child(2) {
  border-color: #46dff0;
  animation-delay: -0.5s;
}

.layout_loadingSpinner__rYhu4 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.layout_loadingDivs__27TTh {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.layout_loadingDivs__27TTh div {
  box-sizing: content-box;
}

.maincontent_mainContent__3ZZQf {
  height: 100%;
  overflow-y: auto;
  background-color: #eff2ff;
  transition: all 0.5s ease 0s;
  margin-left: 35px;
}
@media screen and (max-width: 600px) {
  .maincontent_mainContent__3ZZQf {
    margin-left: 0;
  }
}

/* @media all and (min-width: 600px) {

    .mainContent {
        margin-left: 175px;
    }
} */

.maincontent_rotated__36DYE {
  transform: translate3d(100px, 0px, -600px) rotateY(-20deg);
  transform-style: preserve-3d;
  overflow: hidden;
}

.maincontent_page__1DT_5 {
  min-height: calc(100% - 140px);
}

.topheader_topHeader__2M-gX {
    border-bottom: 2px solid #3f51b5;
}


@media all and (min-width: 600px) {
    .topheader_menuButton__2Z4Hj {
        display: none !important;
    }
}
.sidenav_sideNav__2KSyZ {
  background-color: #3f51b5 !important;
  border-right: 1px solid black !important;
  z-index: 1000000 !important;
}
.sidenav_navItemText__1qP67 {
  font-family: "Roboto", sans-serif !important;
  font-size: 16px !important;
  padding-bottom: 5px !important;
}

.sidenav_open__1R8z_ {
  right: inherit;
  z-index: 1100;
  width: 300px;
  text-align: left;
}

.maincontent_mainContent__2QnK9 {
    height: 100%;
    overflow-y: auto;
    background-color: white;
    transition: all 0.5s ease 0s;
}

.maincontent_rotated__3QJ0B {
    transform: translate3d(100px, 0px, -600px) rotateY(-20deg);
    transform-style: preserve-3d;
    overflow: hidden;
}

.maincontent_page__2Z9Sg {
    min-height: calc(100% - 140px);
}
.maincontent_mainContent__2yd14 {
    height: 100%;
    overflow-y: auto;
    background-color: white;
    transition: all 0.5s ease 0s;
}

.maincontent_rotated__3muGq {
    transform: translate3d(100px, 0px, -600px) rotateY(-20deg);
    transform-style: preserve-3d;
    overflow: hidden;
}

.maincontent_page__1EUQv {
    min-height: calc(100% - 140px);
}
.topheader_topHeader__2P80f {
    border-bottom: 2px solid #3f51b5;
}
html {
  height: 100%;
}
body {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style: none;
}

* {
  outline: none !important;
}

a {
  color: inherit;
}

a:hover {
  text-decoration: inherit;
  color: inherit;
}

.color-primary {
  color: #3f51b5 !important;
}

.bg-primary {
  background-color: #3f51b5 !important;
}
.bg-secondary {
  background-color: #f50057 !important;
}

.MuiTimelineItem-missingOppositeContent:before {
  padding: 0 !important;
  flex: 0 1 !important;
}

.loadingContainer {
  width: 120px;
  height: 120px;
  overflow: hidden;
}

.loadingContainer div {
  border-radius: 50%;
  padding: 4px;
  border: 2px solid transparent;
  box-sizing: border-box;
  height: 100%;
  border-top-color: #3f51b5;
  animation: loadingRotate 4s infinite linear;
}

@keyframes loadingRotate {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

