.layout {
  height: 100%;
  perspective: 1500px;
  overflow: hidden;
}

@keyframes loadingAnimation {
  0 % {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0;
  }
}

.loadingDivs div {
  position: absolute;
  border-width: 4px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: loadingAnimation 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loadingDivs div:nth-child(1) {
  border-color: #e90c59;
}

.loadingDivs div:nth-child(2) {
  border-color: #46dff0;
  animation-delay: -0.5s;
}

.loadingSpinner {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.loadingDivs {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.loadingDivs div {
  box-sizing: content-box;
}
