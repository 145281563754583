.page1 {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  border-radius: 16px;
  border: 3px solid #3f51b5;
  box-shadow: #3f51b5 60px -16px;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 8rem auto;
  animation: translateL 0.5s linear;
  animation-range: entry 0 cover 50%;
}

.newAccountButton {
  font-size: 20px !important;
  background-color: #3f51b5 !important;
  padding: 10px 20px !important;
  color: white !important;
  width: 237px !important;
  height: 66px !important;
  border-radius: 60px !important;
  border: 1px ridge #3f51b5 !important;
}

.newAccountButton:hover {
  background-color: white !important;
  color: #3f51b5 !important;
  border: 1px solid #3f51b5 !important;
  transition: all 0.3s ease-in-out !important;
}

.homeImg {
  width: 70%;
  max-width: 100%;
  height: auto;
  margin-bottom: 2rem;
}

.logo {
  max-width: 100%;
  height: auto;
}

.overlay h5 {
  font-size: 2rem;
  width: 100%;
  color: #3f51b5; /* Adjust text color as needed */
  margin-right: 1rem;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  border-radius: 16px;
  margin-bottom: 3rem;
  text-align: end;
  font-weight: bold;
}
.overlay h5 span {
  color: #ee6c4d;
  font-weight: bold;
}
@media (max-width: 1200px) {
  .page1 {
    box-shadow: 0px;
    display: flex;
    align-items: center;
    padding: 10px;
    box-shadow: none;
    border: 2px solid #3f51b5;
    flex-direction: column;
    margin: 10rem auto;
  }

  .newAccountButton {
    font-size: 16px !important;
    width: 237px !important;
    height: 66px !important;
    padding: 8px 16px !important;
    margin: 1rem 0 !important;
  }

  .homeImg {
    width: 60%;
  }

  .overlay h5 {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .overlay {
    padding: 0 10px;
  }
}

@media (max-width: 480px) {
  .newAccountButton {
    font-size: 14px !important;
    width: 200px !important;
    height: 55px !important;
    padding: 6px 12px !important;
    margin: 1rem 0 !important;
  }

  .overlay h5 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  .overlay img {
    width: 100%;
    height: auto;
  }

  .homeImg {
    display: none;
  }
  .page2 {
    flex-direction: column;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    scale: 0.7;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}
@keyframes translateL {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes translateR {
  0% {
    opacity: 0;
    transform: translateX(300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
