.card {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 1rem 0;
  border: 3px solid #3f51b5;
  background-color: #ffffff;
  border-radius: 16px;
}
.title {
  width: 100% !important;
  font-size: 1.3rem !important;
  font-weight: bold;
  color: white;
  border: 3px solid #3f51b5 !important;
  background-color: #3f51b5 !important;
  border-radius: 12px 12px 0 0;
}
.smallDescription {
  font-family: "roboto", sans-serif;
  font-size: 1.3rem !important;
  margin-top: 2rem;
  color: #3f51b5;
}
.content img {
  width: 180px;
  height: 180px;
  margin-bottom: 1rem;
  /* border-radius: 50%; */
}
.content {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem 1rem 2.5rem 1rem;
}

.description li {
  text-align: start;
  font-size: 1rem !important;
  margin: 1rem 0;
  font-family: "Roboto", sans-serif;
}
.description {
  padding: 1rem;
}

.doneIcon {
  margin-right: 3px;
  color: #00b894;
}
.price {
  font-size: 1.5rem !important;
  font-weight: bold;
  margin: 2rem auto;
  width: 100%;
}
.buyButton {
  font-size: 16px !important;
  background-color: #3f51b5 !important;
  padding: 10px 20px !important;
  color: white !important;
  width: 276px !important;
  height: 66px !important;
  border-radius: 60px !important;
  border: 1px ridge #3f51b5 !important;
}

.buyButton:hover {
  background-color: white !important;
  color: #3f51b5 !important;
  border: 1px solid #3f51b5 !important;
  transition: all 0.3s ease-in-out !important;
}

.disabledButton {
  font-size: 16px !important;
  background-color: white !important;
  color: #3f51b5 !important;
  border: 1px solid #3f51b5 !important;
  width: 80% !important;
  height: 66px !important;
  border-radius: 60px !important;
}
.priceButton {
  display: flex;
  margin: 0 1rem;

  flex-direction: row;
  justify-self: space-between;
  gap: 4rem;
}
.priceButtonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
}
/* Container for the number buttons */
.numberButtons {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between buttons */
}

/* Individual number button styling */
.numberButton {
  width: 30px; /* Width of each button */
  height: 30px; /* Height of each button */
  border-radius: 50%; /* Rounded corners for circular shape */
  border: 1px solid #3f51b5; /* Optional border */
  background-color: #ffffff; /* Background color */
  color: #3f51b5; /* Text color */
  font-size: 14px; /* Font size */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Cursor changes to pointer on hover */
}
.selected {
  background-color: #3f51b5 !important; /* Optional hover effect */
  color: #ffffff !important;
}

.numberButton:hover {
  background-color: #3f51b5; /* Optional hover effect */
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}
.months {
  font-size: 14px;
  font-family: "roboto", sans-serif;
  font-weight: bold;
}

@media (max-width: 480px) {
  .card {
    max-height: 700px;
  }
  .content img {
    width: 140px;
    height: 140px;
    margin: 0;
  }
  .priceButton {
    margin-bottom: 5px;
  }
  .description {
    margin-bottom: 0;
  }
  .numberButtons {
    gap: 6px;
  }
  .numberButton {
    width: 28px;
    height: 28px;
    font-size: 12px;
  }
  .months {
    font-size: 14px;
  }
  .price {
    font-size: 1.4rem !important;
    margin: auto;
  }
  .buyButton {
    width: 90% !important;
    height: 50px !important;
    font-size: 14px !important;
    margin-top: 12px !important;
  }
  .priceButton {
    gap: 2rem;
  }
  .disabledButton {
    width: 70% !important;
    height: 50px !important;
  }
  .doneIcon {
    margin-right: 2px;
  }
  .description li {
    font-size: 1rem !important;
    margin: 0.7rem auto;
  }
  .smallDescription {
    font-size: 1rem !important;
    margin: 0.5rem;
    display: none;
  }
  .title {
    font-size: 1.1rem !important;
  }
}
