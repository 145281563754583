html {
  scroll-behavior: smooth;
}
.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  padding: 3rem;
  margin: 0.75rem;
  background-color: #f3f5ff;
  background-image: radial-gradient(
      #3f51b5 0.9500000000000001px,
      transparent 0.9500000000000001px
    ),
    radial-gradient(#3f51b5 0.9500000000000001px, #ffffff 0.9500000000000001px);
  background-size: 38px 38px;
  background-position: 0 0, 19px 19px;
}
.container h1 {
  font-family: "poppins", sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  color: #3f51b5;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
  background-color: white;
}
.offersButton {
  font-size: 16px !important;
  padding: 10px 20px !important;
  width: 276px !important;
  height: 66px !important;
  border-radius: 60px !important;
  transition: all 0.3s !important;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.container h3 {
  font-family: "poppins", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: #616161;
  margin-bottom: 0.5rem;
  background-color: white;
}
.container p {
  font-family: "poppins", sans-serif;
  font-size: 1.15rem;
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 0;
  background-color: white;
}
.buttonContainer {
  margin: 15px 0;
}
.button {
  margin: 3rem !important;
  font-family: "poppins", sans-serif !important;
  font-size: 2.15rem !important;

  cursor: pointer !important;
  border-radius: 50% !important;
  text-align: center !important;
  width: 150px !important;
  height: 150px !important;

  transition: all 0.3s !important;
}
.button1 {
  color: #4caf50 !important;
  background-color: #ffffff !important;
  border: 1px solid #4caf50 !important;
}
.button2 {
  color: #3f51b5 !important;
  background-color: #ffffff !important;
  border: 1px solid #3f51b5 !important;
}
.button3 {
  color: #f44336 !important;
  background-color: #ffffff !important;
  border: 1px solid #f44336 !important;
}
.button1:hover {
  color: #ffffff !important;
  background-color: #4caf50 !important;
}
.button2:hover {
  color: #ffffff !important;
  background-color: #3f51b5 !important;
}
.button3:hover {
  color: #ffffff !important;
  background-color: #f44336 !important;
}
.cardContainer {
  box-shadow: #3f51b5 0px 0px 10px 0px;
  min-height: 400px;
  margin: 0 auto !important;
  width: 480px !important;
}
.green {
  box-shadow: #4caf50 0px 0px 10px 0px;
  border: 1px solid #4caf50;
}
.blue {
  box-shadow: #3f51b5 0px 0px 10px 0px;
  border: 1px solid #3f51b5;
}
.red {
  box-shadow: #f44336 0px 0px 10px 0px;
  border: 1px solid #f44336;
}
.header {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  margin: 7px 0;
}
.header h4 {
  font-family: "poppins", sans-serif !important;
  font-size: 1.5rem !important;
  font-weight: bold !important;
  color: #727272 !important;
}
.panel1 {
  font-family: "poppins", sans-serif !important;
  font-weight: bold !important;
  font-size: 1.1rem !important;
  margin: 10px 0 !important;
}
@media screen and (max-width: 490px) {
  .container {
    padding: 1.5rem 0.5rem !important;
  }
  .container p {
    text-align: center;
  }
  .button {
    margin: 1rem !important;
    width: 100px !important;
    height: 100px !important;
    font-size: 1.5rem !important;
  }
  .cardContainer {
    min-height: 400px;
    margin: 0 auto !important;
    width: 95% !important;
  }
  .header h4 {
    font-size: 1.3rem !important;
  }
}
