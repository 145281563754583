.box {
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.5), 0 2px 4px 0 rgba(0,0,0,.5);
    -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,.5), 0 2px 4px 0 rgba(0,0,0,.5);
    -moz-box-shadow: 0 2px 4px 0 rgba(0,0,0,.5),0 2px 4px 0 rgba(0,0,0,.5);
    background-color: #fff;
    padding: 38px 47px 28px 53px;
    margin-bottom: 14px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

ul {
    padding: 0;
}



li {
    position: relative;
    font-size: 1.125em;
    margin: 5px;
}

span {
    font-weight: bold;
    margin-left: 7.5px;
}