.active {
  background-color: #3f51b5;
  color: white;
}

.icon {
  width: 30px !important;
  height: 30px !important;
  text-align: center;
  color: white !important;
}

.active .icon {
  color: white;
}
.label {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  padding-bottom: 5px;
  color: white;
}
