.mainContent {
    height: 100%;
    overflow-y: auto;
    background-color: white;
    transition: all 0.5s ease 0s;
}

.rotated {
    transform: translate3d(100px, 0px, -600px) rotateY(-20deg);
    transform-style: preserve-3d;
    overflow: hidden;
}

.page {
    min-height: calc(100% - 140px);
}