/* Ensure the card container has perspective for the 3D effect */
.cardContainer {
  perspective: 1000px;
  width: 100%; /* Ensure full width of the card */
  height: 300px; /* Adjust height as per your needs */
}

/* The card itself */
.card {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s ease-in-out;
}
.cardMobile {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;

  align-items: center;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #5c6bc0;
  background-color: white;
  border: 2px solid #5c6bc0;
}
.mobileButton {
  background-color: white !important;
  color: #5c6bc0 !important;
  border: 2px solid #5c6bc0 !important;
  border-radius: 5px !important;
  padding: 10px 15px !important;
  cursor: pointer !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 18px;
}
.mobileimg {
  width: 85px;
  height: 85px;
  border-radius: 8px;
  margin-bottom: 4px;
}
.button {
  background-color: #5c6bc0 !important;
  color: white !important;
  border: 2px solid white !important;
  border-radius: 5px !important;
  padding: 10px 15px !important;
  cursor: pointer !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 18px;
}

/* Trigger the flip on hover */
.cardContainer:hover .card {
  transform: rotateY(180deg);
}

/* Front and back side of the card */
.cardFront,
.cardBack {
  width: 100%;
  height: 80%;
  position: absolute;
  backface-visibility: hidden; /* Hide the backside when flipped */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: white;
}
.smallIcon {
  font-size: 16px;
  position: absolute;
  top: -20px;
  right: -20px;
}

/* Front side styling */
.cardFront {
  background-color: #5c6bc0; /* Indigo color */
}

/* Back side starts rotated */
.cardBack {
  background-color: #ffffff;
  color: #5c6bc0;
  justify-content: space-evenly;
  flex-direction: column;
  border: 2px solid #5c6bc0; /* Reddish color */
  transform: rotateY(180deg);
}
.buttonBack {
  background-color: white !important;
  color: #5c6bc0 !important;
  border: 2px solid #5c6bc0 !important;
  border-radius: 5px !important;
  padding: 10px 15px !important;
  cursor: pointer !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
}
.buttonBack:hover {
  background-color: #5c6bc0 !important;
  color: white !important;
}
.img {
  width: 100px;
  height: 100%;
  border-radius: 8px;
}
@media screen and (max-width: 600px) {
  .smallIcon {
    font-size: 12px !important;
    height: 42px !important;
    top: -20px;
    right: -25px;
  }
}
