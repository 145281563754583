@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html {
  height: 100%;
}
body {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style: none;
}

* {
  outline: none !important;
}

a {
  color: inherit;
}

a:hover {
  text-decoration: inherit;
  color: inherit;
}

.color-primary {
  color: #3f51b5 !important;
}

.bg-primary {
  background-color: #3f51b5 !important;
}
.bg-secondary {
  background-color: #f50057 !important;
}

.MuiTimelineItem-missingOppositeContent:before {
  padding: 0 !important;
  flex: 0 !important;
}

.loadingContainer {
  width: 120px;
  height: 120px;
  overflow: hidden;
}

.loadingContainer div {
  border-radius: 50%;
  padding: 4px;
  border: 2px solid transparent;
  box-sizing: border-box;
  height: 100%;
  border-top-color: #3f51b5;
  animation: loadingRotate 4s infinite linear;
}

@keyframes loadingRotate {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}
