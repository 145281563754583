.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  padding: 3rem;
  background-color: #f3f5ff;
  background-image: radial-gradient(
      #3f51b5 0.9500000000000001px,
      transparent 0.9500000000000001px
    ),
    radial-gradient(#3f51b5 0.9500000000000001px, #ffffff 0.9500000000000001px);
  background-size: 38px 38px;
  background-position: 0 0, 19px 19px;
}
.container h1 {
  font-family: "poppins", sans-serif;
  font-size: 3rem;
  font-weight: bold;
  color: #3f51b5;
  margin-bottom: 2.5rem;
  background-color: white;
}
.container p {
  font-family: "poppins", sans-serif;
  font-size: 1.15rem;
  font-weight: 600;
  background-color: white;
}

.menu {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  gap: 64px;
  flex-wrap: wrap;
  justify-content: center;
}
.menuItem {
  padding: 0.4rem;
  font-family: "poppins", sans-serif;
  font-size: 1.15rem;
  font-weight: 600;
  background-color: white;
  cursor: pointer;
}
.listItem {
  padding: 0.4rem;
  font-family: "poppins", sans-serif;
  font-size: 1.15rem;
  font-weight: 600;
  cursor: pointer;
}
.selected {
  color: #3f51b5 !important;
  border-bottom: 2px solid #3f51b5 !important;
}
.menuItem:hover {
  color: #3f51b5 !important;
  transition: all 0.2s ease-in-out !important;
}
