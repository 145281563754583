.title {
  color: #3f51b5;
  font-family: "poppins", sans-serif;
}
.container {
  padding: 5rem;
}
@media (max-width: 480px) {
  .container {
    padding: 3rem 0rem;
  }
}
