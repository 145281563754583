.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 1200px) {
  .page1 {
    box-shadow: 0px;
    display: flex;
    align-items: center;
    padding: 10px;
    box-shadow: none;
    border: 2px solid #3f51b5;
    flex-direction: column;
  }

  .newAccountButton {
    font-size: 16px !important;
    width: auto !important;
    height: auto !important;
    padding: 8px 16px !important;
  }

  .homeImg {
    width: 60%;
  }

  .overlay h5 {
    font-size: 1.5rem;
    text-align: end;
    margin-bottom: 1.5rem;
  }

  .overlay {
    padding: 0 10px;
  }
}

@media (max-width: 480px) {
  .newAccountButton {
    font-size: 14px !important;
    width: auto !important;
    height: auto !important;
    padding: 6px 12px !important;
  }

  .overlay h5 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  .overlay img {
    width: 100%;
    height: auto;
  }

  .homeImg {
    display: none;
  }
  .page2 {
    flex-direction: column;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    scale: 0.7;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}
@keyframes translateL {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes translateR {
  0% {
    opacity: 0;
    transform: translateX(300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
