.cardContainer {
  display: flex;
  justify-content: center;
}
.card {
  width: 350px;
  height: 300px;
  margin: 18px;
  padding: 10px;
  border-radius: 36px;
  border: 3px solid #3f51b5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.feedback {
  font-size: 1.1rem;
  font-family: "Roberts", sans-serif;
}
@media screen and (max-width: 480px) {
  .card {
    width: 280px;
    margin-right: 30px;
  }
  .feedback {
    font-size: 1rem;
  }
}
