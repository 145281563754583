.sideNav {
  background-color: #3f51b5 !important;
  border-right: 1px solid black !important;
  z-index: 1000000 !important;
}
.navItemText {
  font-family: "Roboto", sans-serif !important;
  font-size: 16px !important;
  padding-bottom: 5px !important;
}

.open {
  right: inherit;
  z-index: 1100;
  width: 300px;
  text-align: left;
}
