.container {
  margin-top: 65px;
  padding-right: 20px;
  padding-left: 20px;
}
@media screen and (max-width: 600px) {
  .container {
    padding-right: 0px;
    padding-left: 0px;
  }
}
